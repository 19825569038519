import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../images/logo.png'
import styled from 'styled-components'

const Logo = styled.div`
  width: 120px;
  margin: 0 auto;
`;

const Header = ({ siteTitle }) => (
  <header>
    <div className="container mx-auto py-16 px-5 text-center max-w-screen-lg">
      <Logo>
        <Link to="/">
          <img style={{width: '120px'}} src={logo} alt={siteTitle}/>
        </Link>
      </Logo>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
